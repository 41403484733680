import styled from "styled-components";
import { MAX_WIDTH } from "../../constants/layout";
import heroImage3 from "../../assets/heroImage3.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0 10px;
`;
export const HomeContainer = styled.div`
  padding: 0;
`;

export const ContainerContent = styled.div`
  max-width: ${MAX_WIDTH};
  width: 100%;
`;


export const HomeContainerContent = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 0;
`;

export const Container2 = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${heroImage3});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
