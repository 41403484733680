import styled from "styled-components";
import { sm, md } from "../../constants/layout";

export const BlogsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-bottom: 30px;

  @media screen and (max-width: ${md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${sm}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BlogItem = styled.div`
  background: #fff;
  a {
    text-decoration: none;
  }

  :hover {
    cursor: pointer;
    background: #033a72;
  }
  :hover * {
    color: #fff;
  }
`;

export const BlogInfo = styled.div`
  display: flex;
`;

export const BlogInfoIcon = styled.span`
  color: #b7b7b7;
  margin-right: 8px;
`;

export const BlogContainer = styled.div`
  padding: 15px 20px;
`;

export const BlogInfoItem = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
`;

export const BlogSubTitle = styled.div`
  margin-top: 10px;
  height: 69px;
  line-height: 1.2rem;
  overflow: hidden;
`;
