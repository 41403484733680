import styled from "styled-components";
import colors from "../../constants/colors";

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  width: 66%;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InfoWrap = styled.div`
  padding: 5px 0 15px;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0;
`;

export const InfoIcon = styled.span`
  color: ${colors.primary};
  display: flex;
  margin-right: 10px;
`;

export const RightColumn = styled.div`
  display: flex;
  width: 33%;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputListWrap = styled.div`
  padding: 10px 0;
`;

export const MessageTextArea = styled.textarea`
  width: 100%;
  border: none;
  background-color: white;
  padding: 10px 20px;
`;

export const ErrorText = styled.p`
  font-size: 0.7rem;
  color: red;
  font-weight: 300;
  margin-top: 2px;
`;
