import React from "react";
import { HomeContainer, HomeContainerContent } from "./ContainerElements";

export default ({ children }) => {
  return (
    <HomeContainer>
      <HomeContainerContent>{children}</HomeContainerContent>
    </HomeContainer>
  );
};
