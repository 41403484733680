import styled from "styled-components";
import signin from "../../assets/signin.jpg";
import { FaRegCheckCircle } from "react-icons/fa";
import { md, sm } from "../../constants/layout";

export const Wrapper = styled.div`
  display: flex;
  padding-bottom: 50px;

  @media screen and (max-width: ${md}) {
    flex-direction: column-reverse;
  }
`;

export const LeftColumn = styled.div`
  flex: 1;
  padding: 40px;
  background-image: url(${signin});
  background-size: contain;
  background-position: center;

  @media screen and (max-width: ${sm}) {
    padding: 20px;
  }
`;

export const InfoList = styled.div`
  margin-top: 20px;
`;

export const InfoItem = styled.div`
  display: flex;
  padding: 8px 0;
`;

export const InfoIcon = styled(FaRegCheckCircle)`
  color: #fff;
  margin-right: 10px;
  font-size: 22px;
`;

export const RegisterBtn = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 60px;
`;

export const RightColumn = styled.div`
  flex: 2;
  padding: 40px;
  background-color: #fff;

  @media screen and (max-width: ${sm}) {
    padding: 20px;
  }
`;

export const InputListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const InputWrap = styled.div`
  width: 49%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const RememberMe = styled.div`
  display: flex;
  align-items: center;
`;

export const RememberMeChecked = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #13a8bf;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  margin-right: 8px;
`;

export const RememberMeUnChecked = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #13a8bf;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 8px;
`;

export const LoginBtn = styled.div`
  padding-bottom: 20px;
`;

export const ForgetPassword = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorText = styled.p`
  font-size: 0.7rem;
  color: red;
  font-weight: 300;
  margin-top: 2px;
`;

export const SocialButton = styled.div`
  background-color: #4382d8;
  border-radius: 4px;
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocialIcon = styled.span`
  color: #fff;
  font-size: 18px;
  margin-right: 6px;
  margin-top: 2px;
`;
