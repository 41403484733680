import React from "react";
import moment from "moment";
import { InfoWrap, InfoHeaderWrap } from "./LoanCommentsElements";
import Typography from "../Typography/Typography";
import colors from "../../constants/colors";
import ClipLoader from "react-spinners/ClipLoader";
import {
  LikeCommentWrap,
  MoreBtn,
} from "../../screens/home/communityBoard/communityElements";
import { FaRegComment } from "react-icons/fa";
import { useMutation } from "react-query";
import useLoanPostLike from "../../hooks/useLoanPostLike";
import { AiOutlineLike } from "react-icons/ai";
import { FiMoreHorizontal, FiSend } from "react-icons/fi";
import ReportDialog from "../ReportDialog/ReportDialog";
import SendMessage from "../SendMessage/SendMessage";

export default ({ data, isLoading, refetchLoanDetail }) => {
  const [isSendMessageOpen, setIsSendMessageOpen] = React.useState(false);
  const [isReportOpen, setIsReportOpen] = React.useState(false);

  const [myLike, setMyLike] = React.useState(0);
  const [totalLike, setTotalLike] = React.useState(0);

  const [selectedBoard, setSelectedBoard] = React.useState({
    boardId: null,
    userId: null,
  });

  const [mutateLoanPostLike] = useMutation(useLoanPostLike, {
    onSuccess: () => refetchLoanDetail(),
  });

  React.useEffect(() => {
    if (data) {
      setMyLike(data.my_like);
      setTotalLike(data.total_like);
    }
  }, [data]);

  if (isLoading) {
    return <ClipLoader size={28} color={colors.primary} loading={true} />;
  }

  const {
    id,
    title,
    state,
    // loan_amount,
    created_at,
    description,
    category,
    image,
    user,
    total_comments,
  } = data || {};

  const info = [
    {
      id: 1,
      label: "Category",
      value: category,
    },
    {
      id: 2,
      label: "State",
      value: state,
    },
    // {
    //   id: 2,
    //   label: "City",
    //   value: city,
    // },
    // {
    //   id: 3,
    //   label: "Zip Code",
    //   value: zip_code,
    // },
    // {
    //   id: 4,
    //   label: "LTV",
    //   value: ltv ? `${ltv}% LTV` : null,
    // },
    // {
    //   label: "Property Occupancy",
    //   value:
    //     property_occupancy &&
    //     property_occupancy.map(({ title }) => title).join(", "),
    // },
    // {
    //   id: 5,
    //   label: "User Type",
    //   value: user_type,
    // },
    // {
    //   label: "Property Value",
    //   value: property_value ? `$${getAmount(property_value)}` : null,
    // },
    // {
    //   id: 6,
    //   label: "Deposit",
    //   value: deposit,
    // },
  ];

  const handleLike = () => {
    setMyLike(!myLike);
    setTotalLike(myLike ? totalLike - 1 : totalLike + 1);
    mutateLoanPostLike({
      loan_post_id: id,
    });
  };

  return (
    <>
      <ReportDialog
        open={isReportOpen}
        close={() => setIsReportOpen()}
        id={selectedBoard.boardId}
      />
      <SendMessage
        boardId={selectedBoard.boardId}
        userId={selectedBoard.userId}
        open={isSendMessageOpen}
        close={() => setIsSendMessageOpen(false)}
      />
      <InfoHeaderWrap>
        <div style={{ display: "flex", marginBottom: 12 }}>
          <img
            src={user?.image}
            style={{
              width: 50,
              height: 50,
              borderRadius: 30,
              backgroundColor: "#efefef",
              objectFit: "contain",
            }}
          />
          <div style={{ marginLeft: 12 }}>
            <Typography body1>{user?.name}</Typography>
            <div>
              <Typography body2>
                {moment(created_at).format("MMMM DD, YYYY")}
              </Typography>
            </div>
          </div>
        </div>
      </InfoHeaderWrap>
      <div style={{ marginTop: 20, marginBottom: 6 }}>
        <div
          style={{
            fontWeight: 500,
            color: "#555",
            fontSize: 18,
          }}
        >
          {title}
        </div>
        {description && (
          <div
            style={{ color: "#666" }}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </div>
      <InfoWrap >
        {/* {loan_amount ? (
          <div
            key={id}
            style={{
              display: "flex",
              paddingRight: 50,
              margin: "10px 0",
            }}
          >
            <Typography body1Bold>Loan Amount:&nbsp;</Typography>
            <Typography body1> ${getAmount(loan_amount)}</Typography>
          </div>
        ) : null} */}
        {info.map(({ id, label, value }) =>
          value ? (
            <div
              key={id}
              style={{ display: "flex", paddingRight: 50, margin: "10px 0" }}
            >
              <Typography body1Bold>{label}:&nbsp;</Typography>
              <Typography body1>
                {label === "State" ? value.name : value}
              </Typography>
            </div>
          ) : null
        )}
      </InfoWrap>
      <img
        src={image}
        style={{
          height: 320,
          margin: "10px 0",
          width: "100%",
          objectFit: "contain",
        }}
      />
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ paddingRight: 12 }}>
            <LikeCommentWrap onClick={handleLike}>
              <AiOutlineLike
                size={20}
                color={myLike ? colors.primary : "#444"}
              />
              <div
                style={{
                  marginLeft: 6,
                  fontSize: 13,
                  color: myLike ? colors.primary : "#666",
                }}
              >
                {totalLike ? totalLike : null} Like
              </div>
            </LikeCommentWrap>
          </div>
          <div style={{ paddingRight: 12 }}>
            <LikeCommentWrap>
              <FaRegComment size={18} color="#555" />
              <div style={{ marginLeft: 6, fontSize: 13, color: "#666" }}>
                {total_comments > 0 && total_comments} Comment
              </div>
            </LikeCommentWrap>
          </div>
          <LikeCommentWrap
            onClick={() => {
              setIsSendMessageOpen(true);
              setSelectedBoard({
                boardId: id,
                userId: user.id,
              });
            }}
          >
            <FiSend size={18} color="#555" />
            <div style={{ marginLeft: 6, fontSize: 13, color: "#666" }}>
              Send Message
            </div>
          </LikeCommentWrap>
        </div>
        <MoreBtn
          onClick={(e) => {
            e.stopPropagation();
            setIsReportOpen(true);
            setSelectedBoard({
              boardId: id,
              userId: user.id,
            });
          }}
        >
          <FiMoreHorizontal size={22} color="#555" />
        </MoreBtn>
      </div>
    </>
  );
};
