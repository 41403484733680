import React from "react";
import {
  HeroSection,
  HeroContainer,
  // HeroDescription,
  HeroSearchSection,
  SearchCategory,
  CategoryBtn,
  SearchInputSection,
  SearchInput,
  SearchButton,
  SearchIcon,
} from "./heroElements";
import { FaSearch } from "react-icons/fa";
import { Typography } from "../../../components";
import { useHistory } from "react-router-dom";
// import usePageContent from "../../../hooks/usePageContent";

export default ({ categories }) => {
  const history = useHistory();
  // const { data } = usePageContent("home");
  const [selectedCatetory, setSelectedCatetory] = React.useState(
    categories[0].title
  );
  const [searchText, setSearchText] = React.useState("");

  const handleChangeSearchText = (e) => setSearchText(e.target.value);

  const handleSearch = () => {
    history.push({
      pathname: "/homeSearchList",
      search: `?type=${selectedCatetory}&searchText=${searchText}`,
    });
  };

  return (
    <HeroSection>
      <HeroContainer>
        {/* <Typography h1>{data && data.title}</Typography> */}
        <div>
          <h1 style={{letterSpacing:'3px',
    color: '#033a72',
    backgroundColor: 'rgb(255, 255, 255, 0.3)',
    padding: '3px 10px',
    textAlign: 'center'}}>Instantly Connect with Leading Real Estate Experts</h1>
        </div>
        {/* <Typography h1>Your Dream Property</Typography> */}
        {/* <HeroDescription>
          <Typography body1 color="#fff">
            Take a deep dive and browse original neighborhood photos, drone
            footage, resident reviews and local insights to see if the homes for
            sale are right for you.
          </Typography>
        </HeroDescription> */}
        <HeroSearchSection>
          <SearchCategory >
            {categories.map(({ id, title }, index) => (
              <CategoryBtn 
                key={id}
                borderTopLeft={index === 0}
                borderTopRight={index === categories.length - 1}
                selected={selectedCatetory === title}
                onClick={() => setSelectedCatetory(title)}
              >
                {title}
              </CategoryBtn>
            ))}
          </SearchCategory>
          <SearchInputSection>
            <SearchInput
              placeholder={`Search in ${selectedCatetory}`}
              value={searchText}
              onChange={handleChangeSearchText}
            />
            <SearchButton onClick={handleSearch}>
              <SearchIcon>
                <FaSearch />
              </SearchIcon>
              <Typography body1 color="#fff">
                Search
              </Typography>
            </SearchButton>
          </SearchInputSection>
        </HeroSearchSection>
      </HeroContainer>
    </HeroSection>
  );
};
