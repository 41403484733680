import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
  LoadMore,
} from "../../components";
import useAssociations from "../../hooks/useAssociations";
// import profileImage from "../../assets/profileImage.png";
import {
  AssociationsWrap,
  AssociationsItem,
  AssociationAvatar,
  AssociationsSubTitle,
  AssociationsContainer,
  AssociationBtn,
  AssociationLickIcon,
} from "./associationsElements";
import { IoIosGlobe } from "react-icons/io";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const [list, setList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, isSuccess } = useAssociations(currentPage);

  React.useEffect(() => {
    if (data) {
      setList([...list, ...data.associations]);
    }
  }, [data]);

  return (
    <>
      <HeroSection
        title="Associations"
        description="Learn About Other Industry Associations."
      />
      <Container>
        <BreadCrumbs data={[{ title: "Associations" }]} />
        {isLoading && list.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <AssociationsWrap>
            {list.map((
              { id, title, image, description, link } //  slug, content,
            ) => (
              <AssociationsItem key={id}>
                <AssociationsContainer>
                  <AssociationAvatar src={image} />
                  <AssociationsSubTitle>
                    <Typography h3>{title}</Typography>
                    {link && (
                      <a
                        target="_blank"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (link.indexOf("http") > -1) {
                            window.open(link);
                          } else {
                            window.open(`http://${link}`);
                          }
                        }}
                      >
                        <AssociationBtn style={{backgroundColor:'#033a72'}}>
                          <AssociationLickIcon>
                            <IoIosGlobe />
                          </AssociationLickIcon>
                          <Typography body2 color="#FFF">
                            Visit Website
                          </Typography>
                        </AssociationBtn>
                      </a>
                    )}
                    {description && (
                      <Typography body1>
                        {ReactHtmlParser(description)}
                      </Typography>
                    )}
                  </AssociationsSubTitle>
                </AssociationsContainer>
              </AssociationsItem>
            ))}
          </AssociationsWrap>
        )}
        <LoadMore
          dataPerPage={data}
          allData={list}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Container>
    </>
  );
};
