import styled from "styled-components";
import colors from "../../constants/colors";
import { sm } from "../../constants/layout";

export const ContentWrap = styled.div`
  background-color: #fff;
  padding: 25px 30px;
  margin-bottom: 50px;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TagWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const Tag = styled.span`
  background-color: ${colors.primary};
  border-radius: 20px;
  color: #fff;
  font-size: 13px;
  padding: 5px 12px;
  margin-right: 10px;
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CommentBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
`;

export const CommentBoxWrap = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const CommentTextAreaWrap = styled.div`
  /* padding-left: 20px; */
  flex: 1;
`;

export const CommentTextArea = styled.textarea`
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px;
  color: #494949;
`;

export const CommentWrap = styled.div`
  padding: 15px 20px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
`;

export const CommentProfileWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const CommentUserPicture = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 30px;
  object-fit: cover;
`;

export const CommentUserNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 18px;
`;

export const EditComment = styled.span`
  cursor: pointer;
  margin-right: 10px;
  :hover {
    color: blueviolet;
  }
`;

export const DeleteComment = styled.span`
  cursor: pointer;
  :hover {
    color: red;
  }
`;

export const InfoHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${sm}) {
    flex-direction: column;
  }
`;

export const ReplyWrap = styled.span`
  cursor: pointer;
  padding: 2px 10px;
  color: #999;
  :hover {
    color: #666;
  }
`;
