import React from "react";
import { ViewAll, ViewAllIcon } from "./ViewAllElements";
import { FaAngleRight } from "react-icons/fa";

export default (props) => {
  return (
    <ViewAll {...props} white={props.white}>
      <span
        style={{
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.21px",
          color: `${props.white ? "#fff" : "#033a72"}`,
        }}
      >
        View All
      </span>
      <ViewAllIcon white={props.white}>
        <FaAngleRight />
      </ViewAllIcon>
    </ViewAll>
  );
};
