import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  HeroSection,
  BreadCrumbs,
  Typography,
  Button,
  FormLabel,
  FormInput,
} from "../../components";
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  // InfoList,
  // InfoItem,
  // InfoIcon,
  RegisterBtn,
  InputListWrap,
  InputWrap,
  // RememberMe,
  // RememberMeChecked,
  // RememberMeUnChecked,
  LoginBtn,
  ForgetPassword,
  ErrorText,
} from "./signInElements";
// import { FaCheck } from "react-icons/fa";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import useSignIn from "../../hooks/useSignIn";
import { useMutation } from "react-query";
import { AppContext } from "../../AppContext";
import Alert from "@material-ui/lab/Alert";
import ForgetPasswordModal from "./forgetPasswordModal";
import {
  setLocalStorageToken,
  setLocalStorageUser,
} from "../../utilities/setStorage";
import ResendVerification from "./ResendVerification";
// import SocialLogin from "./socialLogin";
import { Redirect } from "react-router-dom";

// const formData = [
//   {
//     name: "emailAddress",
//     label: "Email Address",
//     placeholder: "Enter Your Email Address",
//     type: "email",
//     value: "bimalgrg519@gmail.com",
//     required: true,
//   },
//   {
//     name: "password",
//     label: "Password",
//     placeholder: "Enter Your Password",
//     type: "password",
//     value: "S0lution",
//     required: true,
//   },
// ];

const formData = [
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter Your Email Address",
    type: "email",
    value: "",
    required: true,
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Enter Your Password",
    type: "password",
    value: "",
    required: true,
  },
];

export default () => {
  const history = useHistory();
  const { values, handleChange } = useForm(formData);
  const [formErrors, setFormErrors] = React.useState({});
  // const [isRememberMe, setIsRememberMe] = React.useState(false);
  const { currentUser, setCurrentUser } = React.useContext(AppContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = React.useState(false);
  const [isResendVerificationVisible, setIsResendVerificationVisible] =
    React.useState(false);
  const [userEmail, setUserEmail] = React.useState(false);

  const [mutateSignIn, { isLoading }] = useMutation(useSignIn, {
    onSuccess: (response) => {
      const data = response.data.data;
      setLocalStorageToken(data.token);
      setLocalStorageUser(data.user);
      // history.push("/profile/basic");
      setCurrentUser(data.user);
    },
    onError: (error) => {
      const { data, code, message } = error.response.data;
      setErrorMessage(message);

      if (data && data.user_email && code.toString() === "403") {
        setIsResendVerificationVisible(true);
        setUserEmail(data.user_email);
      }
    },
  });

  if (currentUser) {
    return <Redirect to={"/profile/basic"} />;
  }

  // const toggleRememberMe = () => setIsRememberMe(!isRememberMe);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsResendVerificationVisible(false);

    const tempErrors = validate(values);
    if (Object.keys(tempErrors).length) {
      setFormErrors(tempErrors);
    } else {
      mutateSignIn({
        email: values[0].value,
        password: values[1].value,
      });
    }
  };

  return (
    <>
      <HeroSection
        title="Login"
        description="Please login or register your account"
      />
      <Container>
        <BreadCrumbs data={[{ title: "Login" }]} />
        <Wrapper>
          <LeftColumn>
            <Typography h1>Welcome to</Typography>
            <Typography h1>CRE Guide</Typography>
            <Typography h3 color="#fff" marginTop={15}>
              Register now to join.
            </Typography>
            {/* <InfoList>
              {[
                "Lorem ipsum dolor sit amet, consectetur 1",
                "Lorem ipsum dolor sit amet, consectetur 2",
                "Lorem ipsum dolor sit amet, consectetur 3",
              ].map((d) => (
                <InfoItem key={d}>
                  <InfoIcon />
                  <Typography body1 color="#fff">
                    {d}
                  </Typography>
                </InfoItem>
              ))}
            </InfoList> */}
            <RegisterBtn>
              <Button white onClick={() => history.push("/signUp")}>
                Register
              </Button>
            </RegisterBtn>
          </LeftColumn>
          <RightColumn style={{border:'1px solid #ddd'}}>
            <ForgetPasswordModal
              open={isForgetPasswordOpen}
              close={() => setIsForgetPasswordOpen(false)}
            />
            <form onSubmit={handleSubmit}>
              <Typography h2 color={'#033a72'}>Login</Typography>
              <Typography body1>Please login to your account!</Typography>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              {isResendVerificationVisible && (
                <ResendVerification
                  userEmail={userEmail}
                  resetError={() => setErrorMessage("")}
                />
              )}
              <InputListWrap>
                {values.map(
                  ({ name, label, placeholder, type, value, required }) => (
                    <InputWrap key={name}>
                      <FormLabel htmlFor={name} required={required}>
                        {label}
                      </FormLabel>
                      <FormInput
                        id={name}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                        style={{border:'1px solid #ddd',borderRadius:"5px",paddingLeft:"5px"}}
                      />
                      {<ErrorText>{formErrors[name]}</ErrorText>}
                    </InputWrap>
                  )
                )}
              </InputListWrap>
              {/* <RememberMe>
                {isRememberMe ? (
                  <RememberMeChecked onClick={toggleRememberMe}>
                    <FaCheck />
                  </RememberMeChecked>
                ) : (
                  <RememberMeUnChecked onClick={toggleRememberMe} />
                )}
                <Typography body1>Remember Me</Typography>
              </RememberMe> */}
              <LoginBtn>
                <Button isLoading={isLoading}>Login</Button>
              </LoginBtn>
              <ForgetPassword onClick={() => setIsForgetPasswordOpen(true)}>
                <Typography body1>Forgot Password?</Typography>
              </ForgetPassword>
            </form>
            {/* <SocialLogin /> */}
          </RightColumn>
        </Wrapper>
      </Container>
    </>
  );
};
