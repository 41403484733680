import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../../components";
import useBlogDetail from "../../../hooks/useBlogDetail";
// import profileImage from "../../assets/profileImage.png";
import {
  BlogImage,
  BlogInfo,
  BlogInfoElement,
  InfoIcon,
  BlogContent,
  BlogTitle,
  BlogContainer,
  BlogContentContainer,
} from "./blogDetailElements";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { blogId } = useParams();
  const { isLoading, data } = useBlogDetail(blogId);

  return (
    <>
      <HeroSection
        title={data && data.title}
        description={data && data.sub_title}
      />
      <Container>
        <BreadCrumbs
          data={[
            { title: "Market Insights", pathname: "/market-insights" },
            { title: `${data && data.title}` },
          ]}
        />
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <BlogContainer>
            <BlogImage img={data && data.image} />
            <BlogContentContainer>
              <BlogInfo>
                <InfoIcon>
                  <FaUserCheck />
                </InfoIcon>
                <BlogInfoElement>{data && data.author}</BlogInfoElement>
                <InfoIcon>
                  <MdDateRange />
                </InfoIcon>
                <BlogInfoElement>
                  {data && moment(data.date).format("MMMM DD, YYYY")}
                </BlogInfoElement>
              </BlogInfo>
              <BlogTitle>{data && data.title}</BlogTitle>
              <BlogContent>{data && ReactHtmlParser(data.content)}</BlogContent>
            </BlogContentContainer>
          </BlogContainer>
        )}
      </Container>
    </>
  );
};
