import React from "react";
import {
  SocialTitle,
  CategoryWrapper,
  Category,
  CategoryText,
} from "./socialSearchElements";
import { Typography } from "../../../components";
import { useHistory } from "react-router-dom";
import { ReactComponent as CommunityBoardLogo } from "../../../assets/bulletinBoard.svg";
import { ReactComponent as AssociationsLogo } from "../../../assets/associations.svg";
import { ReactComponent as ResourcesLogo } from "../../../assets/resources.svg";
import { ReactComponent as NewsLogo } from "../../../assets/news.svg";
import { ReactComponent as JobBoard } from "../../../assets/jobBoard.svg";
import { ReactComponent as Events } from "../../../assets/events.svg";

export default () => {
  const history = useHistory();
  return (
    <>
      <SocialTitle style={{paddingTop:"25px"}}>
        <Typography h2>Social Search</Typography>
      </SocialTitle>
      <CategoryWrapper>
        {[
          {
            title: "Community Board",
            logo: <CommunityBoardLogo className="category-icon" />,
          },
          {
            title: "Events",
            logo: <Events className="category-icon" />,
          },
          {
            title: "News & Articles",
            logo: <NewsLogo className="category-icon" />,
          },
          {
            title: "Job Board",
            logo: <JobBoard className="category-icon" />,
          },
          {
            title: "Resources",
            logo: <ResourcesLogo className="category-icon" />,
          },
          {
            title: "Associations",
            logo: <AssociationsLogo className="category-icon" />,
          },
        ].map(({ title, logo }) => (
          <Category style={{border:"1px solid #ddd"}}
            key={title}
            onClick={() => {
              if (title === "Community Board") {
                history.push("/communityBoard");
              } else if (title === "Associations") {
                history.push("/associations");
              } else if (title === "Resources") {
                history.push("/resources");
              } else if (title === "News & Articles") {
                history.push("/news");
              } else if (title === "Job Board") {
                history.push("/jobBoard");
              } else if (title === "Events") {
                history.push("/events");
              }
            }}
          >
            {logo}
            <CategoryText >{title}</CategoryText>
          </Category>
        ))}
      </CategoryWrapper>
    </>
  );
};
