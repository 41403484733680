import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
  Image,
  LoadMore,
} from "../../components";
import useBlogs from "../../hooks/useBlogs";
// import profileImage from "../../assets/profileImage.png";
import {
  BlogsWrap,
  BlogItem,
  BlogInfo,
  BlogInfoItem,
  BlogInfoIcon,
  BlogSubTitle,
  BlogContainer,
} from "./blogsElements";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export default () => {
  const [blogList, setBlogList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, isSuccess } = useBlogs(currentPage);

  React.useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setBlogList(data.blogs);
      } else {
        setBlogList([...blogList, ...data.blogs]);
      }
    }
  }, [data]);

  return (
    <>
      <HeroSection title="Market Insights" description="Industry Thoughts" />
      <Container>
        <BreadCrumbs data={[{ title: "Market Insights" }]} />
        {isLoading && blogList.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <BlogsWrap>
            {blogList.map((
              { id, name, image, excerpt, author } //  slug, content,
            ) => (
              <BlogItem key={id}>
                <Link to={`/market-insight/${id}`}>
                  <Image
                    style={{
                      width: "100%",
                      height: 250,
                    }}
                    src={image}
                  />
                  <BlogContainer>
                    <BlogInfo>
                      <BlogInfoItem>
                        <BlogInfoIcon>
                          <BsFillPersonCheckFill />
                        </BlogInfoIcon>
                        <Typography body2>{author}</Typography>
                      </BlogInfoItem>
                    </BlogInfo>
                    <Typography h3>{name}</Typography>
                    <BlogSubTitle>
                      {excerpt && <Typography body1>{excerpt}</Typography>}
                    </BlogSubTitle>
                  </BlogContainer>
                </Link>
              </BlogItem>
            ))}
          </BlogsWrap>
        )}
        <LoadMore
          dataPerPage={data}
          allData={blogList}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Container>
    </>
  );
};
