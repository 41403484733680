import styled from "styled-components";
import testbanner from "../../assets/testbanner.jpg";
import { MAX_WIDTH } from "../../constants/layout";

export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${testbanner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 10px;
`;

export const HeroSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${MAX_WIDTH};
  width: 100%;
  height: 180px;
`;

export const DescriptionWrap = styled.div`
  max-width: 750px;
`;
